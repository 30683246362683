import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	InputLabel,
	TextField
} from "@mui/material";
import React, { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";

interface IAddToMailingListDialogProps {
	open: boolean;
	onClose: () => void;
}

const AddToMailingListDialog: FC<IAddToMailingListDialogProps> = (props) => {
	const { open, onClose } = props;

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle textAlign="center" color="white" classes={{ root: "dialog-title-root primary" }}>
				Add to Mailing List
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<InputLabel htmlFor="name-input" sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}>
							Name of the Person
						</InputLabel>

						<TextField
							fullWidth
							size="small"
							id="name-input"
							variant="filled"
							placeholder="Add name of the person"
							InputProps={{ disableUnderline: true, hiddenLabel: true }}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<InputLabel
							htmlFor="contact-number-input"
							sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
						>
							Contact Number
						</InputLabel>

						<TextField
							fullWidth
							size="small"
							id="contact-number-input"
							variant="filled"
							placeholder="Add contact number"
							InputProps={{ disableUnderline: true, hiddenLabel: true }}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<InputLabel
							htmlFor="email-address-input"
							sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
						>
							Email Address
						</InputLabel>

						<TextField
							fullWidth
							size="small"
							id="email-address-input"
							variant="filled"
							placeholder="Add email address"
							InputProps={{ disableUnderline: true, hiddenLabel: true }}
						/>
					</Grid>

					<Grid item xs={12}>
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							<Button disableElevation variant="contained" color="success" sx={{ paddingX: 4 }}>
								Save
							</Button>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default AddToMailingListDialog;
