import { Navigate, createBrowserRouter } from "react-router-dom";
import { LicenseInfo } from "@mui/x-license-pro";
import Login from "../screens/Login";
import PageLayout from "./PageLayout";
import NestedPageLayout from "./NestedPageLayout";
import Uptime from "../screens/Uptime";
import AuthorizedList from "../screens/DataManagement/AuthorizedList";
import Blacklist from "../screens/DataManagement/Blacklist";
import EventLogs from "../screens/EventLogs";
import DeviceCameraSettings from "../screens/Configuration/DeviceCameraSettings";
import CommunicationChannels from "../screens/Configuration/CommunicationChannels";
import SocietyConfiguration from "../screens/Configuration/SocietyConfiguration";
import Dashboard from "../screens/Dashboard";
import CustomFields from "../screens/Configuration/CustomFields";

LicenseInfo.setLicenseKey(
	"8d70d9731170b6417efb13bf3e89d1f8Tz05MTc4NCxFPTE3NDkwMjEyODAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

export const router = createBrowserRouter([
	{
		path: "/",
		element: <Navigate to="/login" replace />
	},
	{
		path: "/login",
		element: <Login />
	},
	{
		element: <PageLayout />,
		children: [
			{
				path: "/dashboard",
				element: <Dashboard />
			},
			{
				path: "/uptime",
				element: <Uptime />
			},
			{
				path: "/event-logs",
				element: <EventLogs />
			},
			{
				path: "/data-management",
				element: <NestedPageLayout />,
				children: [
					{
						path: "/data-management",
						element: <Navigate to="/data-management/authorized-list" replace />
					},
					{
						path: "/data-management/authorized-list",
						element: <AuthorizedList />
					},
					{
						path: "/data-management/blacklist",
						element: <Blacklist />
					}
				]
			},
			{
				path: "/configuration",
				element: <NestedPageLayout />,
				children: [
					{
						path: "/configuration",
						element: <Navigate to="/configuration/device-and-camera-settings" replace />
					},
					{
						path: "/configuration/device-and-camera-settings",
						element: <DeviceCameraSettings />
					},
					{
						path: "/configuration/property-configuration",
						element: <SocietyConfiguration />
					},
					{
						path: "/configuration/communication-channels",
						element: <CommunicationChannels />
					},
					{
						path: "/configuration/custom-fields",
						element: <CustomFields />
					}
				]
			}
		]
	}
]);
