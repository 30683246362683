import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import React, { FC, useState } from "react";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

import "./communicationChannels.css";
import { ROWS_PER_PAGE } from "../../../utils/constants";
import AddToMailingListDialog from "./AddToMailingListDialog";

interface ICommunicationChannelRowDetails {
	id: number;
	sr_no: number;
	name: string;
	start_date: string;
	email_address: string;
	contact_number: string;
}

const rowsData: ICommunicationChannelRowDetails[] = [
	{
		id: 1,
		sr_no: 1,
		name: "John Doe",
		start_date: moment().format("DD-MM-YYYY_HH:mm"),
		email_address: "johndoe@example.com",
		contact_number: "9191929293"
	},
	{
		id: 2,
		sr_no: 1,
		name: "Jane Doe",
		start_date: moment().subtract(1, "day").format("DD-MM-YYYY_HH:mm"),
		email_address: "janedoe@example.com",
		contact_number: "9393929291"
	}
];

const CommunicationChannels: FC = () => {
	const [showAddToMailingListDialog, setShowAddToMailingListDialog] = useState<boolean>(false);

	function handleOpenAddToMailingListDialog() {
		setShowAddToMailingListDialog(true);
	}

	function handleCloseAddToMailingListDialog() {
		setShowAddToMailingListDialog(false);
	}

	function renderActionsColumnCell() {
		return (
			<Box className="communication-channels-table-actions-column-cell">
				<IconButton size="small" color="error">
					<DeleteIcon fontSize="inherit" />
				</IconButton>
			</Box>
		);
	}

	const columnsData: GridColDef[] = [
		{ field: "sr_no", headerName: "Sr. No.", width: 100, sortable: false },
		{ field: "name", headerName: "Name", minWidth: 200, flex: 1, sortable: false },
		{
			field: "start_date",
			headerName: "Start Date",
			minWidth: 150,
			flex: 1,
			sortable: false,
			valueFormatter: (params: GridValueFormatterParams<string>) =>
				moment(params.value, "DD-MM-YYYY_HH:mm").format("DD/MM/YYYY")
		},
		{ field: "contact_number", headerName: "Contact Number", minWidth: 150, flex: 1, sortable: false },
		{ field: "email_address", headerName: "Email Address", minWidth: 220, flex: 1.5, sortable: false },
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: renderActionsColumnCell
		}
	];

	return (
		<Box className="communication-channels-screen-wrapper">
			<Box className="communication-channels-screen-actions-wrapper">
				<Button
					variant="outlined"
					color="success"
					size="small"
					startIcon={<AddCircleIcon />}
					sx={{ paddingX: 2 }}
					onClick={handleOpenAddToMailingListDialog}
				>
					Add
				</Button>
			</Box>

			<Box className="communication-channels-screen-header-wrapper">
				<Typography variant="h5" fontWeight={500}>
					Mailing List
				</Typography>
				<Divider sx={{ marginY: "0.5rem", borderColor: "var(--color-text-primary)" }} />
			</Box>

			<Box className="communication-channels-table-wrapper">
				<DataGrid
					columns={columnsData}
					rows={rowsData}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={60}
					pageSizeOptions={[ROWS_PER_PAGE]}
					paginationModel={{ page: 0, pageSize: ROWS_PER_PAGE }}
					hideFooter
					classes={{
						columnSeparator: "communication-channels-table-column-separator",
						columnHeader: "communication-channels-table-column-header"
					}}
				/>
			</Box>

			<AddToMailingListDialog open={showAddToMailingListDialog} onClose={handleCloseAddToMailingListDialog} />
		</Box>
	);
};

export default CommunicationChannels;
